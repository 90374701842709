#subscription {
  min-height: 70vh;
}

.subscription-form-container {
  display: flex;
  min-height: 70vh;
  /* justify-content: center; */
  /* margin: 50px 25px; */
}

.subscription-form-container > div {
  flex: 1;
}

.subscription-form {
  background-color: #fff;
  margin: 50px auto;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* width: 100%; */
  max-width: 400px;
  text-align: center;
}

h2 {
  color: #59b829;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

/* .input-group {
    margin-bottom: 20px;
    text-align: left;
  }
  
  .input-group label {
    display: block;
    color: #333;
    margin-bottom: 5px;
  }
  
  .input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  } */

.subscribe-button {
  background-color: #59b829;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}

.subscribe-button:hover {
  background-color: #53ce15;
}

#message {
  margin-top: 20px;
}

@media screen and (max-width: 992px) {
  .subscription-form {
    max-width: 300px;
  }
}

@media screen and (max-width: 767px) {
  .subscription-form-container {
    flex-direction: column-reverse;
  }
  .subscription-form-container > div:first-child {
    z-index: 100;
  }
  .subscription-form {
    margin-top: -50px;
  }
}
