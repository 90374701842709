#my-plan {
  margin: 25px 0px;
  min-height: 70vh;
}

#my-plan .loader {
  margin-top: 50px auto;
}

#my-plan .no-plan {
  margin: 10px auto;
  padding: 0px 0px 25px 0px;
  display: inline-block;
}

#my-plan .no-plan img {
  max-width: 500px;
}

#my-plan .ai-meal-plan {
  padding: 15px 20px;
  font-size: 1.2em;
  background-color: rgb(53, 93, 213);
}

#my-plan .ai-meal-plan img {
  margin-top: -2px;
  width: 25px;
}

#my-meal-plan {
  padding: 0px 50px;
}

#my-meal-plan .days {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 1.25;
}

#my-meal-plan .days h3 {
  text-transform: capitalize;
}

#my-meal-plan .days > div {
  width: 25%;
  background: #ffffff;
  border-top: 10px solid #63a363;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px 20px;
  margin: 10px;
  text-align: left;
}

#my-meal-plan .days > div:nth-of-type(odd) {
  border-top: 10px solid #feb33e;
}

#my-meal-plan .days ul {
  margin: 5px 0px 15px 15px;
}

#my-meal-plan .days li {
  list-style-type: disc;
}

#my-meal-plan .day {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#my-meal-plan .edit {
  font-size: 0.9em;
  text-decoration: underline;
  cursor: pointer;
}

#my-meal-plan .more-info {
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-top: 15px;
  margin-bottom: 50px;
}

#my-meal-plan .more-info ul {
  border-left: 5px solid;
  flex: 1;
  padding: 20px 25px;
  font-size: 1em;
  list-style-type: disc;
}

#my-meal-plan .more-info ul:first-child {
  margin-right: 15px;
}

#my-meal-plan .more-info li {
  margin-bottom: 5px;
  line-height: 1.4;
}

#my-meal-plan .modal h3,
#my-meal-plan .modal label {
  text-transform: capitalize;
}

#my-meal-plan .modal label {
  display: block;
  margin-bottom: 5px;
}

#my-meal-plan .modal .meal {
  margin-bottom: 10px;
}

@media screen and (max-width: 992px) {
  #my-meal-plan .days > div {
    width: 40%;
  }
}

@media screen and (max-width: 767px) {
  #my-meal-plan {
    padding: 0px 20px;
  }
  #my-meal-plan .days {
    justify-content: space-between;
  }
  #my-meal-plan .days > div {
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
  }
  #my-meal-plan p {
    font-size: 0.9em;
  }
  #my-meal-plan .more-info {
    flex-direction: column;
  }
  #my-meal-plan .more-info ul:first-child {
    margin-right: 0px;
    margin-bottom: 15px;
  }
}
