.newsletter-container {
  background-color: #fff;
  padding: 40px;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  margin: 20px auto;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

#newsletter {
  margin: 70px 50px;
}

.newsletter-container h3 {
  color: #333;
  margin-bottom: 10px;
}

.newsletter-container p {
  color: #666;
  margin-bottom: 30px;
}

.newsletter-form {
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

#newsletter .form-control {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 50px;
  flex-grow: 1;
  width: 100%;
}

#newsletter .form-control i {
  color: #007bff;
  margin-right: 10px;
}

#newsletter .form-control input {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 10px;
  width: 100%;
}

#newsletter .btn {
  padding: 20px 30px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

#newsletter .btn:hover {
  background-color: #0056b3;
}

#message {
  margin-top: 20px;
}

@media screen and (max-width: 767px) {
  #newsletter {
    margin-left: 25px;
    margin-right: 25px;
  }
  .newsletter-form {
    flex-direction: column;
  }
}
