.search{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 40%;
    height: 40px;
    margin: auto;
    padding: 5px 8px;
    background: rgb(255, 255, 255);
    border-radius: 0.8rem;
    box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
    border: 1px solid rgb(232, 232, 232);
    font-size: 1.2em;
  }
  
  .search input{
    width: 100%;
    border: none;
    outline: none;
    border-radius: 0.8rem;
    font-weight: 400;
  }
  
  .search svg{
    margin: 0px 8px;
    width: 35px;
    height: 35px;
    cursor: pointer; 
  }

  @media screen and (max-width: 767px) {
    .search{
        width: 90%;
        font-size: 1.1em;
    }
}