#signup-form .container {
  margin: 20px auto;
  min-height: 70vh;
}

#signup-form {
  background-color: #fff;
  margin: 50px auto;
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  /* width: 100%; */
  max-width: 400px;
  text-align: center;
}

#signup-form button[disabled] {
  background-color: rgb(89 184 41 / 50%);
}

#signup-form .terms {
  font-size: 0.75em;
  line-height: 1.5;
}

#signup-form a {
  color: rgb(0, 76, 196);
}

#signup-form .login a {
  text-decoration: underline;
}
