footer {
  padding: 25px 40px;
  background-color: rgb(248, 248, 248);
}

footer .brand-name {
  font-size: 2em;
}

footer .info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
}

footer .social {
  display: flex;
  padding-left: 40px;
}

footer .social li img {
  margin: 0px 10px;
  width: 25px;
}

footer .info .app-link img {
  width: 12em;
}

footer .terms {
  text-align: center;
}

@media screen and (max-width: 767px) {
  footer .brand-name {
    margin-bottom: 15px;
  }
  footer .info {
    flex-direction: column;
  }
  footer .social {
    padding: 0px;
  }
}
