header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 15px 40px;
}

header .menu,
header .menu ul {
  display: flex;
  align-items: center;
}

header .menu ul {
  margin-left: 50px;
}

header .menu li {
  font-size: 1.25em;
  margin-right: 25px;
}

header .menu li:hover {
  font-weight: 600;
  cursor: pointer;
}

header .menu li.active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 3px;
}

header .brand-name {
  font-size: 1.6em;
}

header .chevron-left {
  display: none;
}

header .playstore img {
  width: 10em;
}

header .sign-up-btn {
  margin-right: 10px;
}

header .header-key-button.meal-plan {
  margin-right: 10px;
  background-color: rgb(53, 93, 213);
}

header .header-key-button.meal-plan img {
  margin-top: -2px;
  width: 18px;
}

header .profile {
  position: relative;
  display: inline-block;
}

header .profile img {
  width: 40px;
  margin-left: 10px;
}

header .profile .icon-down {
  width: 16px;
}

header .profile ul {
  display: none;
  position: absolute;
  min-width: 100px;
  padding: 0px;
  margin: 0px;
  text-align: left;
  right: 0px;
  border-radius: 10px;
  box-shadow: 0 2px 16px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  background-color: #fff;
}

header .profile li {
  padding: 10px;
}

header .profile li:hover {
  background-color: #eee;
}

header .profile:hover ul {
  display: block;
}

@media screen and (max-width: 767px) {
  header {
    margin: 10px 15px;
    padding: 10px 5px;
  }
  header .menu {
    flex: 1;
    justify-content: center;
  }
  header .mobile-menu .fa-bars {
    font-size: 1.5em;
  }
  header .chevron-left {
    width: 15px;
    display: block;
  }
  header .playstore img {
    width: 0px;
    visibility: hidden !important;
  }
  .subscribe,
  header .header-key-button.meal-plan {
    width: 0px;
    display: none !important;
  }
}
