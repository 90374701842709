#meal-form {
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
  margin: 30px auto;
  overflow: hidden;
}

#meal-form > img {
  width: 100%;
}

#meal-form .steps {
  padding: 20px;
}

.step-indicator {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 500px;
  position: relative;
  margin-bottom: 20px;
}

.step-container {
  flex: 1; /* Ensures that the containers take up equal space */
  display: flex;
  justify-content: center; /* Centers the step number */
  position: relative;
}

.step-container::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%); /* Centers the line vertically */
  left: 50%;
  width: 100%; /* Should stretch to the full width of the step-container */
  height: 2px;
  background-color: #ccc;
  z-index: 0;
}

/* Remove the line after the last step container */
.step-container:last-child::after {
  content: none;
}

.step {
  width: 30px;
  height: 30px;
  line-height: 30px;
  border: 2px solid #ccc;
  border-radius: 50%;
  text-align: center;
  font-size: 16px;
  color: #ccc;
  background-color: #f2f4f8;
  z-index: 1; /* Ensure the step is above the connecting line */
}

/* Highlight the current step */
.step.current {
  border-color: #4caf50;
  color: #fff;
  background-color: #4caf50;
}

.step.completed {
  border-color: #4caf50; /* Green, indicating completion */
  color: #fff;
  background-color: #4caf50; /* You can adjust the color to fit your design */
}

.step.completed::before {
  content: "✔"; /* Checkmark to indicate completion */
  color: #fff;
  font-size: 20px; /* Adjust size as needed */
}

#meal-form .form-container {
  display: flex;
  align-items: center;
  padding: 10px 25px;
}

#meal-form form {
  width: 100%;
  max-width: 500px;
  text-align: left;
}

#meal-form input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 10px;
  margin: 8px 0 20px 0px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

#meal-form input[type="radio"] {
  margin: 8px 5px 20px 0px;
}

#meal-form label {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
  font-weight: 700;
}

#meal-form button {
  background-color: #4caf50; /* Green */
  color: white;
  padding: 12px 20px;
  margin: 8px 0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  left: 100%;
  transform: translateX(-100%);
}

#meal-form button:hover {
  background-color: #45a049;
}

#meal-form button[disabled] {
  background-color: #cccccc;
  color: #666666;
}

.activity-levels,
.goals {
  margin: 20px;
}

.activity-level,
.goal {
  margin-bottom: 8px;
  padding: 2px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.activity-level h3,
.goal h3 {
  margin: 5px 0;
  font-size: 1em; /* Adjust font size as needed */
  color: #333; /* Adjust text color as needed */
}

.activity-level p,
.goal p {
  margin: 5px 0;
  font-size: 0.9em;
  color: #666; /* Adjust text color as needed */
}

.activity-level:hover,
.goal:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* A subtle shadow on hover */
}

/* You can add a class via JavaScript to indicate selection */
.activity-level.selected,
.goal.selected {
  border: 2px solid #4caf50; /* Highlight color for selected activity level */
}

#meal-plan-results {
  padding: 10px 25px;
  text-align: left;
}

#meal-plan-results p,
ul {
  font-size: 0.9em;
}

#meal-plan-results h3 {
  padding: 5px;
  background-color: #eee;
}

#meal-form .search-alt {
  background-color: #eee;
  padding: 8px 20px;
  font-size: 0.9em;
}

#meal-form #signup-form,
#meal-form #login-form {
  box-shadow: none;
  margin-bottom: 10px;
  margin-top: 0px;
}
#meal-form #signup-form .login,
#meal-form #login-form .signup {
  display: none;
}

@media screen and (max-width: 767px) {
  #meal-form {
    margin-left: 20px;
    margin-right: 20px;
    width: auto;
  }
}
