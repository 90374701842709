#dashboard header {
  margin-top: 0px;
  padding-top: 15px;
}
#dashboard nav {
  padding: 20px 50px;
  text-align: right;
}

#dashboard nav img {
  width: 12em;
  margin-right: -15px;
}

#dashboard-hero {
  text-align: center;
  background-image: url("../../img/hero.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* padding-bottom: 15vh; */
}

#dashboard-hero .blur,
#dashboard-hero .hero {
  padding-top: 10vh;
  padding-bottom: 15vh;
}

#dashboard-hero .tagline {
  /* color: #fff; */
  color: #7b6344;
  font-size: 25px;
  line-height: 1.2;
  font-weight: 400;
}

/* #dashboard-hero .search{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 40%;
      height: 40px;
      margin: auto;
      padding: 5px 8px;
      background: rgb(255, 255, 255);
      border-radius: 0.8rem;
      box-shadow: rgb(28 28 28 / 8%) 0px 2px 8px;
      border: 1px solid rgb(232, 232, 232);
      font-size: 1.2em;
    }
    
    #dashboard-hero .search input{
      width: 100%;
      border: none;
      outline: none;
      border-radius: 0.8rem;
      font-weight: 400;
    }
    
    #dashboard-hero .search svg{
      margin: 0px 8px;
      width: 40px;
      height: 40px;
      cursor: pointer; 
    } */

#dashboard .header-key-button.meal-plan {
  margin-top: 40px;
  margin-right: 10px;
  padding: 15px 20px;
  font-size: 1.2em;
  background-color: rgb(53, 93, 213);
}

#dashboard .header-key-button.meal-plan img {
  margin-top: -2px;
  width: 25px;
}

#dashboard #query-suggestions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 5px 10px;
}

#dashboard #query-suggestions .suggestion {
  padding: 8px 10px;
  margin: 5px;
  border: 1px solid #000;
  border-radius: 50px;
  font-size: 0.9em;
}

#dashboard #query-suggestions .suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: #000;
}

#dashboard #key-categories {
  /* background-color: rgb(248, 248, 248); */
  padding: 20px 50px 10px 50px;
}

#dashboard #key-categories ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  margin: 0px;
  padding: 0px;
}

#dashboard #key-categories li {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  /* padding: 15px 25px; */
  width: 22%;
  border-radius: 8px;
  font-size: 1.1em;
  font-weight: 700;
  text-align: center;
  justify-content: center;
  color: #fff;
  text-transform: uppercase;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* cursor: pointer; */
}
#dashboard #key-categories li:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
#dashboard #key-categories a {
  width: 100%;
  padding: 15px;
  display: inline-block;
}
#dashboard #find-alternatives {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 70px 50px;
}
#dashboard #find-alternatives a {
  display: inline-block;
  width: 26%;
  padding: 20px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
}
#dashboard #find-alternatives a:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
#dashboard #find-alternatives img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  margin-bottom: 10px;
  border-radius: 100%;
}
#dashboard #benefit {
  /* display: flex; */
  display: none;
  margin: 0px;
  padding: 0px 50px;
  justify-content: space-between;
  text-align: left;
  background-color: rgb(248, 248, 248);
}

#dashboard #benefit > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
}

#dashboard #benefit h2 {
  font-weight: 600;
  font-size: 2em;
  margin: 0px;
  line-height: 1.4;
}

#dashboard #benefit p {
  margin-top: 10px;
  font-size: 1.1em;
  line-height: 1.5;
}

#dashboard #benefit a img {
  width: 15em;
  margin-left: -15px;
}

#dashboard #misc {
  /* margin: 75px 50px; */
  margin: 75px 0px;
  background-color: #fff;
}

#dashboard #misc .fitness-tools {
  padding: 50px;
  text-align: left;
}

#dashboard #misc .tools {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-gap: 20px;
  align-items: stretch;
}

#dashboard #misc .twitter-timeline {
  width: 40%;
}

#dashboard #misc .fitness-tools .card {
  display: inline-block;
  /* border: 1px solid #d5d5d5; */
  border-radius: 8px;
  padding: 0px;
  background-color: rgb(251, 147, 28);
  color: #fff;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#dashboard #misc .fitness-tools .card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}
#dashboard #misc .fitness-tools .card > div {
  padding: 10px 15px;
}

#dashboard #misc .fitness-tools .card .title {
  margin-bottom: 5px;
  font-weight: 700;
}

#dashboard #misc .fitness-tools .card img {
  /* position: relative;
      left: 50%;
      transform: translateX(-50%);
       */
  width: 100%;
  height: 250px;
  object-fit: cover;
}

#dashboard #misc .fitness-tools button {
  background-color: #fff;
  color: rgb(251, 147, 28);
  border: 1px solid #fff;
  border-radius: 3px;
  margin-top: 20px;
  padding: 5px 8px;
  font-size: 1.2em;
}

@media screen and (max-width: 1130px) {
  #dashboard #misc .tools {
    grid-template-columns: repeat(2, 1fr); /* Adjusted for larger screens */
  }
}

@media screen and (max-width: 992px) {
  #dashboard-hero .tagline {
    font-size: 22px;
  }
  #dashboard #key-categories li {
    font-size: 1em;
    /* width: 16%; */
  }
  #dashboard #benefit > div {
    width: 45%;
  }
  #dashboard #benefit > img {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  #dashboard-hero {
    background-image: url("../../img/hero-mweb.png");
    background-position-y: 80%;
  }

  #dashboard-hero .tagline {
    font-size: 17px;
  }
  #dashboard nav {
    padding-right: 15px;
  }
  #dashboard nav img {
    display: none;
  }
  #dashboard .subscribe-now,
  #dashboard .header-key-button {
    display: inline-block;
  }

  /* #dashboard-hero .search{
        width: 80%;
        font-size: 1.1em;
      } */
  #dashboard #key-categories {
    padding-left: 20px;
    padding-right: 20px;
  }
  #dashboard #key-categories li {
    font-size: 0.9em;
    width: 45%;
  }
  #dashboard #find-alternatives {
    flex-direction: column;
  }
  #dashboard #find-alternatives a {
    width: 100%;
    margin: 15px 0px;
  }
  #dashboard #benefit {
    text-align: center;
    margin: 0px;
    padding: 20px 50px;
  }
  #dashboard #benefit > div {
    width: 100%;
  }
  #dashboard #benefit > img {
    display: none;
  }
  #dashboard #misc {
    flex-direction: column;
    margin: 50px 0px;
  }
  #dashboard#misc .fitness-tools,
  #dashboard #misc .twitter-timeline {
    /* width: 100%; */
    margin-bottom: 50px;
    padding-left: 25px;
    padding-right: 25px;
  }
  #dashboard #misc .fitness-tools h2 {
    text-align: center;
  }
  #dashboard #misc .tools {
    grid-template-columns: 1fr;
  }
  #dashboard #misc .fitness-tools .card img {
    height: 200px;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  #dashboard-hero {
    background-position-y: 75%;
  }
  #dashboard-hero .blur,
  #dashboard-hero .hero {
    padding-bottom: 25vh;
  }
}

@media screen and (max-width: 330px) {
  #dashboard-hero .tagline {
    font-size: 12px;
  }
  #dashboard #key-categories li {
    width: 100%;
  }
  #dashboard #benefit h2 {
    font-size: 1.5em;
  }
  #dashboard #benefit p {
    font-size: 1em;
  }
}
