/* Basic styling for the sidebar menu */
#mobile-menu {
  padding: 10px 0px;
  position: fixed;
  top: 0;
  right: 0;
  width: 250px; /* Sidebar width */
  height: 100vh; /* Full viewport height */
  background-color: #fff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.3);
  transform: translateX(100%); /* Initially hide the sidebar off-screen */
  z-index: 900; /* Below the header but above other content */
  text-align: center;
}

/* Styling for the list and list items in the menu */
#mobile-menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding-top: 20px;
}

#mobile-menu li {
  padding: 15px 20px;
}

#mobile-menu li:hover {
  background-color: #eee;
}

#mobile-menu li a {
  color: #333;
  text-decoration: none;
  font-size: 16px;
  display: block;
}
